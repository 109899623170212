import React, { Component } from "react";

import "./registrationSectionStyles.scss";

class RegistrationSection extends Component {
    render() {
        return (
            <section className="section soft-info-registration-section">
                <div className="container">
                    <h2 className="section_title">
                        Сведения о государственной регистрации ПО «HRFile»
                    </h2>
                    <p>
                        <div className="soft-info-registration-block_item">
                            <span className="title">Номер: </span>
                            <span className="description">2023612796</span>
                        </div>
                        <div className="soft-info-registration-block_item">
                            <span className="title">Дата регистрации: </span>
                            <span className="description">08.02.2023</span>
                        </div>
                    </p>
                </div>
            </section>
        );
    }
}

export default RegistrationSection;
