import React, { Component } from "react";

import "./saasStyleSectionStyles.scss";

class SaasSection extends Component {
    render() {
        return (
            <section className="section soft-info-saas-section">
                <div className="container">
                    <div className="soft-info-saas-block_item">
                        ПО «HRFile» — решение SaaS (Software as a Service).
                        Доступ к полной версии ПО «HRFile» предоставляется путем
                        создания личного кабинета на сайте правообладателя.
                    </div>
                    <div className="soft-info-saas-block_item">
                        Вы можете ознакомиться с основными функциями ПО «HRFile»
                        , войдя в личный кабинет ПО{" "}
                        <a
                            href="https://lk.hrfile.ru/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            «HRFile»
                        </a>{" "}
                        и используя{" "}
                        <a
                            href={
                                "/downloads/Общее руководство по установке и эксплуатации ПО «HRFile».docx"
                            }
                            download
                        >
                            Общее руководство по установке и эксплуатации
                            «HRFile»
                        </a>
                    </div>
                    <div className="soft-info-saas-block_item">
                        Полный перечень функциональности и информация о тарифах
                        доступна на{" "}
                        <a href="https://www.hrfile.ru">www.hrfile.ru</a>
                    </div>
                </div>
            </section>
        );
    }
}

export default SaasSection;
