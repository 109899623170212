import React, { Component } from "react";

import documentsIcon from "../../../images/Documents.svg";

import "./documentsSectionStyles.scss";

class DocumentsSection extends Component {
    render() {
        return (
            <section className="section soft-info-documents-section">
                <div className="container">
                    <div className="soft-info-documents-block_title">
                        Документация
                    </div>
                    <div className="d-flex align-items-center justify-content-center justify-content-md-between flex-column flex-md-row soft-info-documents-block_documents">
                        <div className="soft-info-documents-block_document-card">
                            <div className="soft-info-documents-block_document-card-icon">
                                <img
                                    src={documentsIcon}
                                    alt="Иконка общего руководства"
                                />
                            </div>
                            <a
                                href={
                                    "/downloads/Общее руководство по установке и эксплуатации ПО «HRFile».docx"
                                }
                                className="soft-info-documents-block_document-card-text"
                                download
                            >
                                Общее руководство по установке и эксплуатации ПО
                                «HRFile»
                            </a>
                        </div>
                        <div className="soft-info-documents-block_document-card">
                            <div className="soft-info-documents-block_document-card-icon">
                                <img
                                    src={documentsIcon}
                                    alt="Иконка жизненного цикла"
                                />
                            </div>
                            <a
                                href={
                                    "/downloads/Описание процессов жизненного цикла.docx"
                                }
                                className="soft-info-documents-block_document-card-text"
                                download
                            >
                                Описание процессов жизненного цикла
                            </a>
                        </div>
                        <div className="soft-info-documents-block_document-card">
                            <div className="soft-info-documents-block_document-card-icon">
                                <img
                                    src={documentsIcon}
                                    alt="Иконка инструкции по установке"
                                />
                            </div>
                            <a
                                href={
                                    "/downloads/Инструкция по установке экземпляра программного обеспечения.doc"
                                }
                                className="soft-info-documents-block_document-card-text"
                                download
                            >
                                Инструкция по установке экземпляра программного
                                обеспечения
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DocumentsSection;
