import React, { Component } from "react";
import { Link } from "gatsby";

import Logo from "../../../assets/logo.svg";

import "./header.scss";

class HeaderSection extends Component {
    render() {
        return (
            <header className="header_content sticky-top">
                <div className="container py-3">
                    <Link to="/">
                        <div className="header_content-logo">
                            <Logo className="logo" />
                        </div>
                    </Link>
                </div>
            </header>
        );
    }
}

export default HeaderSection;
