import React, { Component } from "react";

import mainImg from "../../../images/main_img.png";

import "./main.scss";

class MainSection extends Component {
    render() {
        const {
            title = "HRFile – это система кадрового электронного документооборота."
        } = this.props;

        return (
            <section className="section main-section">
                {/* <div className="container"> */}
                <div className="main-left">
                    <h1 className="main-text">{title}</h1>
                </div>
                <div className="main-right">
                    <img src={mainImg} alt="Картинка первого блока" />
                </div>
                {/* </div> */}
            </section>
        );
    }
}

export default MainSection;
