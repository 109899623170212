import React, { Component } from "react";

import Header from "../sections/commonSections/header/HeaderSection";
import Main from "../sections/commonSections/main/MainSection";
import Footer from "../sections/commonSections/footer/FooterSection";

import About from "../sections/softwareInformationPage/aboutSection/AboutSection";
import Classification from "../sections/softwareInformationPage/classificationSection/ClassificationSection";
import CopyrightHolderInfo from "../sections/softwareInformationPage/copyrightHolderInfo/CopyrightHolderInfoSection";
import Registration from "../sections/softwareInformationPage/registrationSection/RegistrationSection";
import Documents from "../sections/softwareInformationPage/documentsSection/DocumentsSection";
import Sass from "../sections/softwareInformationPage/saasSection/SaasSection";

import "../styles/index.scss";

class SoftwareInformation extends Component {
    render() {
        return (
            <div>
                <Header />
                <Main title={["О компании"]} />
                <About />
                <Classification />
                <CopyrightHolderInfo />
                <Registration />
                <Documents />
                <Sass />
                <Footer />
            </div>
        );
    }
}

export default SoftwareInformation;

export const Head = () => <title> HRFile </title>;
