import React, { Component } from "react";

import "./aboutSectionStyles.scss";

class AboutSection extends Component {
    render() {
        return (
            <section className="section soft-info-about-section">
                <div className="container">
                    <h2 className="soft-info-about-block_title">О продукте</h2>
                    <div className="soft-info-about-block_description">
                        <p>
                            HRFile – система кадрового электронного
                            документооборота, использование которой позволяет
                            перейти организациям на кадровый электронный
                            документооборот и повысить тем самым качество и
                            скорость работы отдела кадров. Система обеспечивает
                            надежный и безопасный обмен кадровыми документами в
                            электронном формате без использования бумажных
                            носителей между работодателем, работником или
                            кандидатом на заключение договора. Для каждого
                            работника в HRFile реализован личный кабинет для
                            эффективного участия в процессе электронного обмена
                            кадровыми документами.
                        </p>
                        <p>
                            Программа работает из браузера Google Chrome, Яндекс
                            Браузер, Firefox, Opera и Safari
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutSection;
