import React, { Component } from "react";

import "./classificationSectionStyles.scss";

class ClassificationSection extends Component {
    render() {
        return (
            <section className="section soft-info-classification-section">
                <div className="container">
                    <h2 className="section_title">Классификация ПО «HRFile»</h2>
                    <div className="row g-5">
                        <div className="col-12">
                            <div className="classificator-title mb-2">
                                Раздел
                            </div>
                            <div className="classificator-description">
                                Информационные системы для решения специфических
                                отраслевых задач
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="classificator-title mb-2">
                                Основной класс: 06.12
                            </div>
                            <div className="classificator-description">
                                Системы электронного документооборота
                            </div>
                        </div>
                        {/* <div className="col-12">
                            <div className="classificator-title mb-2">
                                58.29.29
                            </div>
                            <div className="classificator-description">
                                Обеспечение программное прикладное прочее на
                                электронном носителе.
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="classificator-title mb-2">62</div>
                            <div className="classificator-description">
                                Продукты программные и услуги по разработке
                                программного обеспечения; консультационные и
                                аналогичные услуги в области информационных
                                технологий
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="classificator-title mb-2">
                                Раздел
                            </div>
                            <div className="classificator-description">
                                Офисные приложения
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="classificator-title mb-2">
                                Дополнительный класс: 06.12
                            </div>
                            <div className="classificator-description">
                                Программное обеспечение средств электронного
                                документооборота
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="classificator-title mb-2">
                                58.29.21
                            </div>
                            <div className="classificator-description">
                                Приложения общие для повышения эффективности
                                бизнеса и приложения для домашнего пользования,
                                отдельно реализуемы
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="classificator-title mb-2">62</div>
                            <div className="classificator-description">
                                Продукты программные и услуги по разработке
                                программного обеспечения; консультационные и
                                аналогичные услуги в области информационных
                                технологий
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default ClassificationSection;
