import React, { Component } from "react";
import { Link } from "gatsby";

import "./copyrightHolderInfoSectionStyles.scss";

class CopyrightHolderInfoSection extends Component {
    render() {
        return (
            <section className="section soft-info-copyright-holder-section">
                <div className="container">
                    <h2 className="soft-info-copyright-holder-block_title">
                        Сведения о правообладателе ПО «HRFile»
                    </h2>

                    <p>Общество с ограниченной ответственностью «Эйчар Файл»</p>
                    <p>
                        <div>
                            <span className="title">Юридический адрес: </span>
                            <span className="description">
                                109544, город Москва, б-р Энтузиастов, д. 2,
                                этаж 13 часть помещ. 2
                            </span>
                        </div>
                        <div>
                            <span className="title">ИНН: </span>
                            <span className="description">9705159438</span>
                        </div>

                        <div>
                            <span className="title">КПП: </span>
                            <span className="description">770501001</span>
                        </div>

                        <div>
                            <span className="title">ОГРН: </span>
                            <span className="description">1217700437547</span>
                        </div>
                    </p>
                    <p>
                        <div>
                            <span className="title">Сайт: </span>
                            <span className="description">
                                <Link to="/" className="text-link">
                                    https://hrfile.ru/
                                </Link>
                            </span>
                        </div>
                        <div>
                            <span className="title">Электронная почта: </span>
                            <span className="description">
                                <a
                                    href="mailto:info@HRFile.ru"
                                    className="text-link"
                                >
                                    info@HRFile.ru
                                </a>
                            </span>
                        </div>
                    </p>
                </div>
            </section>
        );
    }
}

export default CopyrightHolderInfoSection;
